import { ethers } from "ethers";
import { useState, useEffect, useContext } from "react";
import Connection from "../../contexts/contract";
import axios from "axios";
import ConnectModal from "../Lobby/ConnectModal";
import fire_table from "../../img/brand/fire_table.png";
import MintModal from "../Lobby/MintModal";
import IdModal from "../Lobby/IdModal";

const Tables = () => {
  const [tables, setTables] = useState([]);
  const [rewards, setRewards] = useState([]);
  const [addIds, setAddIds] = useState([]);
  const [dateToSign, setDateToSign] = useState(null);
  const [sig, setSig] = useState(null);
  const [tblId, setTblId] = useState(null);
  const [idToPlay, setIdToPlay] = useState(null);
  const [verified, setVerified] = useState(false);
  const [mintPopUp, setMintPopUp] = useState(false);
  const [chooseId, setChooseId] = useState(false);

  const { address, signer, nftContract } = useContext(Connection);

  const playPoker = async (tableId) => {
    const timestamp = Date.now();
    setDateToSign(timestamp.toString());
    setTblId(tableId);
  };

  const getTableList = () => {
    // console.log("in table list");
    const baseUrl = process.env.REACT_APP_RESTAPI_URL;
    // axios.get();
    console.log(baseUrl);
    axios.get(baseUrl + "api/tables").then(
      (response) => {
        // console.log("response",response);
        if (response && response.data) {
          if (response.data.tableList && response.data.tableList.length > 0) {
            setTables(response.data.tableList);
          } else {
            setTables(response.data);
          }
          if (response.data.rewards && response.data.rewards.length > 0) {
            setRewards(response.data.rewards);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    setTimeout(() => {
      getTableList();
    }, 0);
    console.log(`initializing interval`);
    const interval = setInterval(() => {
      getTableList();
    }, 20000);
    return () => {
      console.log(`clearing interval`);
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (dateToSign) {
      const generateSign = async () => {
        const signature = await signer.signMessage(dateToSign);
        console.log("signature", signature);
        setSig(signature);
      };
      generateSign();
    }
  }, [dateToSign, signer]);

  useEffect(() => {
    const verifyMessage = async (message, signature) => {
      try {
        const signerAddr = await ethers.utils.verifyMessage(message, signature);

        console.log("signer Addr", signerAddr);
        if (signerAddr !== address) {
          setVerified(false);
          return;
        }

        const addBalance = await nftContract.balanceOf(signerAddr);
        if (addBalance > 0 && addBalance < 2) {
          const id = await nftContract.tokenOfOwnerByIndex(address, 0);
          setIdToPlay(ethers.utils.formatUnits(id, 0));
          setVerified(true);
        } else if (addBalance >= 2) {
          for (let i = 0; i < addBalance; i++) {
            const id = await nftContract.tokenOfOwnerByIndex(address, i);
            setAddIds((prevIds) =>
              prevIds.includes(ethers.utils.formatUnits(id, 0))
                ? [...prevIds]
                : [...prevIds, ethers.utils.formatUnits(id, 0)]
            );
          }
          setVerified(true);
          setChooseId(true);
        } else setMintPopUp(true);
      } catch (err) {
        console.log(err);
        setVerified(false);
      }
    };
    if (sig) {
      verifyMessage(dateToSign, sig);
    }
  }, [sig, dateToSign, address, nftContract]);

  useEffect(() => {
    if (verified && tblId && idToPlay) {
      const pokerUrl =
        process.env.REACT_APP_RESTAPI_URL +
        "?guid=" +
        address +
        "&tableid=" +
        tblId;
      console.log("pokerUrl", pokerUrl);
      window.open(pokerUrl);
      setVerified(false);
      setIdToPlay(null);
    }
  }, [verified, tblId, address, idToPlay]);

  return (
    <div className="bg-black bg-lobbyBg bg-cover pt-14 text-white">
      <div className="h-[400px]">
        <div className="w-4/5 text-3xl font-bold mx-auto pb-4">Tables</div>
        <div className="w-4/5 rounded-2xl mx-auto bg-black border-2 border-[#979797]">
          <div className="font-semibold text-sm h-12 rounded-t-2xl">
            <div className="w-11/12 flex mx-auto h-full items-center">
              <div className="w-2/12 flex items-center">Table Name</div>
              <div className="w-2/12 flex items-center">Type</div>
              <div className="w-2/12 flex items-center">Players</div>
              <div className="w-[110px]"></div>
            </div>
          </div>

          <div
            className={`
              ${tables.length < 5 ? "" : "overflow-auto"}
              max-h-[230px] w-full rounded-xl bg-[#404040] inn-shadow`}
          >
            {tables.map((data, i) => {
              return (
                <div key={i} className="h-10 text-sm font-semibold">
                  <div
                    className={`flex mx-auto items-center w-11/12 h-full ${
                      i !== tables.length - 1 ? "border-b" : ""
                    } border-gray-400`}
                  >
                    <div className="w-2/12 flex items-center">{data.name}</div>
                    <div className="w-2/12 flex items-center">
                      Texas Hold’em
                    </div>
                    <div className="w-2/12 flex items-center">
                      {data.numPlayers}/{data.maxPlayers}
                    </div>
                    <div
                      className="flex items-center justify-center h-4/6 w-[110px] ml-auto  text-black text-xs font-bold bg-[#FFDC00] shadow-inner rounded-full cursor-pointer"
                      onClick={() => playPoker(data._id)}
                    >
                      Join Table
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="text-sm font-semibold flex items-center h-12 w-11/12 mx-auto">
            * New tables will be added when above are filled.
          </div>
        </div>
      </div>

      <div className="h-[400px]">
        <div className="w-4/5 text-3xl font-bold mx-auto pb-4">
          Daily Leader Board
        </div>
        <div className="w-4/5 rounded-2xl mx-auto bg-black border-2 border-[#979797]">
          <div className="font-semibold text-sm h-12 rounded-t-2xl">
            <div className="w-11/12 flex mx-auto h-full items-center">
              <div className="w-2/12 flex items-center">Rank</div>
              <div className="w-2/12 flex items-center">Player Name</div>
              <div className="w-2/12 flex items-center">
                Table Chips
                <i className="fa fa-info-circle pad_l5" aria-hidden="true"></i>
              </div>
              <div className="w-2/12 flex items-center">Multiplier</div>
              <div className="w-2/12 flex items-center">
                FIRE Winnings{" "}
                <i className="fa fa-info-circle pad_l5" aria-hidden="true"></i>
              </div>
              <div className="w-2/12 flex items-center">
                Daily Missions{" "}
                <i className="fa fa-info-circle pad_l5" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          <div
            className={`
            ${
              rewards.length < 4 ? "inn-shadow-min" : "overflow-auto inn-shadow"
            }
            max-h-[230px] w-full rounded-xl bg-[#404040]`}
          >
            {rewards.map((data, i) => {
              return (
                <div key={i} className="h-10 font-semibold text-sm">
                  <div
                    className={`flex mx-auto items-center w-11/12 h-full ${
                      i !== rewards.length - 1 ? "border-b" : ""
                    } border-gray-400`}
                  >
                    <div className="w-2/12 flex items-center">{data.rank}</div>
                    <div className="w-2/12 flex items-center">{data.guid}</div>
                    <div className="w-2/12 flex items-center">
                      {data.profitLoss}
                    </div>
                    <div className="w-2/12 flex items-center">
                      {data.percentile}x
                    </div>
                    <div className="w-2/12 flex items-center">
                      {data.fireWinning}
                      <img src={fire_table} className="fire_table" alt="logo" />
                    </div>
                    <div className="w-2/12 flex items-center">
                      {data.dailyMission}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <ConnectModal />
      <MintModal mintPopUp={mintPopUp} setMintPopUp={setMintPopUp} />
      <IdModal
        chooseId={chooseId}
        setChooseId={setChooseId}
        ids={addIds}
        setIdToPlay={setIdToPlay}
      />
    </div>
  );
};

export default Tables;
