import Connection from "../../contexts/contract";
import { useContext } from "react";
import { connectWallet } from "../../lib/constants";

const ConnectButton = () => {
  const {
    connected,
    address,
    setProvider,
    setSigner,
    setAddress,
    setConnected,
    setChainId,
  } = useContext(Connection);
  return (
    <div className="w-1/3 2xl:w-1/4 h-2/3 p-0.5 bg-gradient-to-t from-yellow-600 to-yellow-100 rounded-full shadow-orange hover:opacity-85 hidden sm:block">
      <button
        onClick={() =>
          connectWallet(
            setConnected,
            setAddress,
            setProvider,
            setSigner,
            setChainId
          )
        }
        className="bg-gradient-to-l from-brand-darkGold via-brand-lightGold to-brand-darkGold h-full w-full rounded-full uppercase text-black "
      >
        {connected
          ? address.substring(0, 5) + ".." + address.substring(35)
          : "connect wallet"}
      </button>
    </div>
  );
};

export default ConnectButton;
