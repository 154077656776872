import { Link } from "react-router-dom";

const ThirdBanner = () => {
  return (
    <div className="h-110 bg-thirdBg bg-cover flex flex-col text-white justify-center items-center gap-10">
      <div className="text-base sm:text-2xl">You don't have to be a PRO</div>
      <div className="font-ethno text-2xl uppercase sm:text-5xl">
        to win like one
      </div>
      <div className="w-4/5 text-sm text-center text-gray-400 sm:text-2xl sm:max-w-[700px]">
        Get started in just minutes with your MetaMask wallet. Simply mint your
        membership NFT, connect your wallet and hit the tables!
      </div>
      <div className="bg-gradient-to-t from-yellow-600 to-yellow-100 p-0.5 rounded-full w-4/5 sm:w-1/6">
        <Link to="/lobby">
          <button className="h-10 text-black w-full bg-gradient-to-l from-brand-darkGold via-brand-lightGold to-brand-darkGold uppercase font-bold border-orange shadow-orange rounded-full hover:opacity-80 sm:h-16 text-sm sm:text-base">
            play now
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ThirdBanner;
