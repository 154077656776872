import FourthBanner from "../Landing/FourthBanner";
import Main from "../Landing/Main";
import SecondBanner from "../Landing/SecondBanner";
import ThirdBanner from "../Landing/ThirdBanner";
import PlayBanner from "../Mint/PlayBanner";

export default function Home() {
  return (
    <div className="w-full flex flex-col justify-between">
      <Main />
      <SecondBanner />
      <ThirdBanner />
      <FourthBanner />
      <PlayBanner />
    </div>
  );
}
