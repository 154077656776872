const FourthBanner = () => {
  const cards = [
    {
      left: "1",
      title: "Membership",
      text: "Mint your Limited edition Membership now!",
    },
    {
      left: "2",
      title: "Connect",
      text: "Connect your wallet and membership to play",
    },
    {
      left: "3",
      title: "Play",
      text: "Exciting prizes and loyalty rewards",
    },
  ];
  return (
    <div className="h-110 bg-suitsBg bg-cover bg-blend-darken text-white flex flex-col justify-center items-center gap-12 sm:gap-20">
      <div className="font-ethno text-2xl sm:text-5xl uppercase">
        how to play
      </div>
      <div className="flex flex-col items-center justify-center gap-12 sm:flex-row sm:gap-20 sm:justify-start">
        {cards.map((card, i) => (
          <div key={i} className="flex items-center gap-6 mx-auto w-3/5">
            <div className="text-7xl font-semibold text-brand-goldL sm:text-[100px]">
              {card.left}
            </div>
            <div className="flex flex-col gap-4 border-l border-brand-goldL pl-10">
              <div className="font-bold text-xl sm:text-3xl">{card.title}</div>
              <div className="text-sm w-fit sm:text-lg sm:w-full sm:max-w-[260px]">
                {card.text}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FourthBanner;
