import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import icon from "../../img/brand/goldFlameTitle.svg";
import ConnectButton from "./ConnectButton";
import membership from "../../img/brand/membership.svg";
import enLang from "../../img/brand/enLang.svg";
import ConnectButtonMobile from "./ConnectButtonMobile";

const NavBar = () => {
  const location = useLocation();
  const pathname = location?.pathname;

  const [menu, setMenu] = useState(false);

  return (
    <div>
      <div className="h-16 sm:h-24 bg-black flex items-center justify-between uppercase font-bold px-7 sm:px-24">
        <Link to="/">
          <div
            onClick={() => setMenu(false)}
            className="flex items-center gap-2 sm:gap-6 cursor-pointer"
          >
            <img alt="logo" src={icon} className="w-4 sm:w-8" />
            <div className="font-ethno text-base sm:text-2xl text-white uppercase">
              badbeat
            </div>
          </div>
        </Link>
        <div className="flex items-center h-full justify-end gap-16 w-1/2">
          <div
            onClick={() => setMenu(!menu)}
            className={`space-y-1 flex flex-col sm:hidden `}
          >
            <div
              className={`w-6 h-1 bg-gray-200 rounded transform transition duration-500 ${
                menu ? "rotate-45 translate-y-1" : ""
              }`}
            ></div>
            <div
              className={`w-3.5 self-end h-1 bg-gray-200 rounded transform transition duration-500 ${
                menu ? "hidden" : ""
              }`}
            ></div>
            <div
              className={`w-6 h-1 bg-gray-200 rounded transform transition duration-500 ${
                menu ? "-rotate-45 -translate-y-1" : ""
              }`}
            ></div>
          </div>
          <div className="text-white hidden sm:block">
            <Link to="/membership" className="">
              <div
                className={`cursor-pointer hover:text-brand-lightGold ${
                  pathname === "/membership" ? "text-brand-lightGold" : ""
                }`}
              >
                membership
              </div>
            </Link>
          </div>
          <ConnectButton />
        </div>
      </div>
      <div
        className={`${
          menu ? "block" : "hidden"
        } flex flex-col justify-between text-white bg-lobbyBg bg-cover px-6 py-10 h-[743px] sm:hidden`}
      >
        <Link to="/membership">
          <div
            onClick={() => setMenu(false)}
            className=" flex items-center gap-3"
          >
            <img src={membership} alt="membership img" />
            <div
              className={`${
                pathname === "/membership" ? "text-brand-lightGold" : ""
              } font-semibold text-lg`}
            >
              Membership
            </div>
          </div>
        </Link>
        <div className="h-1/4 flex flex-col items-center justify-between">
          <ConnectButtonMobile />
          <img src={enLang} alt="english flag" />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
