import { createContext, useState, useEffect } from "react";
import { ethers } from "ethers";
import { CONTRACT_ADDRESS } from "../lib/constants";
import abi from "../lib/abi.json";

const Connection = createContext();

export const ConnectionProvider = ({ children }) => {
  const [connected, setConnected] = useState(false);
  const [provider, setProvider] = useState(null);
  const [address, setAddress] = useState(null);
  const [signer, setSigner] = useState(null);
  const [mumbaiProvider, setMumbaiProvider] = useState(null);
  const [nftContract, setNftContract] = useState(null);
  const [chainId, setChainId] = useState(null);

  const checkIfWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      return;
    }

    const prov = new ethers.providers.Web3Provider(ethereum);
    setProvider(prov);
    const sign = prov.getSigner();
    setSigner(sign);
    const accounts = await ethereum.request({ method: "eth_accounts" });

    if (accounts.length !== 0) {
      const account = accounts[0];
      setAddress(account);
    } else {
      return;
    }

    setConnected(true);

    const chainId = await ethereum.request({ method: "eth_chainId" });
    setChainId(chainId);
  };

  useEffect(() => {
    checkIfWalletIsConnected();
    const mProvider = new ethers.providers.JsonRpcProvider(
      "https://polygon-mumbai.g.alchemy.com/v2/SeyWmSZubocxNcqaWaiR--xe00RiT1ig"
    );
    setMumbaiProvider(mProvider);
  }, []);

  useEffect(() => {
    if (provider?.getSigner()) {
      provider
        .getSigner()
        .getAddress()
        .then((signerAddress) => {
          setAddress(signerAddress);
        });
      setSigner(provider.getSigner());
    }
  }, [provider, connected]);

  useEffect(() => {
    if (signer || provider || mumbaiProvider) {
      setNftContract(
        new ethers.Contract(
          CONTRACT_ADDRESS,
          abi,
          signer || provider || mumbaiProvider
        )
      );
    }
  }, [provider, signer, mumbaiProvider]);

  useEffect(() => {
    if (chainId && chainId !== "0x13881") {
      const changeChain = async () => {
        const { ethereum } = window;

        if (!ethereum) {
          return;
        }
        try {
          const result = await ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x13881" }],
          });
          if (result === null) {
            const prov = new ethers.providers.Web3Provider(ethereum);
            setProvider(prov);
            const sign = prov.getSigner();
            setSigner(sign);
            const accounts = await ethereum.request({ method: "eth_accounts" });

            if (accounts.length !== 0) {
              const account = accounts[0];
              setAddress(account);
            } else {
              return;
            }

            setConnected(true);
          }
        } catch (switchError) {
          if (switchError.code === 4902) {
            try {
              const result = await ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: "0x13881",
                    rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
                    chainName: "Matic Testnet",
                    nativeCurrency: {
                      name: "MATIC",
                      symbol: "MATIC",
                      decimals: 18,
                    },
                    blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
                  },
                ],
              });
              if (result === null) {
                const prov = new ethers.providers.Web3Provider(ethereum);
                setProvider(prov);
                const sign = prov.getSigner();
                setSigner(sign);
                const accounts = await ethereum.request({
                  method: "eth_accounts",
                });

                if (accounts.length !== 0) {
                  const account = accounts[0];
                  setAddress(account);
                } else {
                  return;
                }

                setConnected(true);
              }
            } catch (err) {
              console.log(err);
            }
          }
        }
      };
      changeChain();
    }
  }, [chainId]);

  return (
    <Connection.Provider
      value={{
        connected,
        provider,
        address,
        signer,
        mumbaiProvider,
        nftContract,
        chainId,
        setProvider,
        setSigner,
        setAddress,
        setConnected,
        setChainId,
      }}
    >
      {children}
    </Connection.Provider>
  );
};

export default Connection;
