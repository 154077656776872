import fireTable from "../../img/brand/goldFlameTitle.svg";
import { Link } from "react-router-dom";

const MintModal = ({ mintPopUp, setMintPopUp }) => {
  return (
    <div
      onClick={() => setMintPopUp(!mintPopUp)}
      className={`${
        mintPopUp ? "" : "hidden"
      } flex items-center justify-center bg-brand-modal overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full`}
    >
      <div className="relative h-full md:h-auto">
        <div className="bg-brand-modalBg py-16 px-32 font-sans flex flex-col gap-6 text-xl relative rounded shadow dark:bg-gray-800">
          <img src={fireTable} className="w-14" alt="fireImg" />
          <div className="flex flex-col gap-3">
            <div className="text-white text-3xl font-bold">Welcome!</div>
            <div className="text-lg text-gray-400 w-[450px] pb-10 border-b-4 border-gray-200">
              To play with BadBeat you must hold a members NFT in your wallet.
            </div>
            <Link to="/membership">
              <div className="bg-gradient-to-t from-yellow-600 to-yellow-100 rounded-full p-0.5 mt-6 w-fit">
                <button
                  onClick={() => setMintPopUp(!mintPopUp)}
                  className="h-14 w-60 text-black bg-gradient-to-l from-brand-darkGold via-brand-lightGold to-brand-darkGold uppercase font-bold shadow-orange rounded-full hover:opacity-80"
                >
                  go to mint
                </button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MintModal;
