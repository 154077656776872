import { Link } from "react-router-dom";

const Main = () => {
  return (
    <div className="w-full pl-0 h-110 flex flex-col justify-center items-center bg-center bg-black bg-blend-screen bg-mainBg2 bg-fixed gap-8 bg-cover bg-no-repeat shadow-black sm:pl-24 sm:items-start sm:bg-left">
      <div className="w-5/6 font-ethno text-3xl text-center text-white text-shadow sm:text-7xl sm:w-3/5 sm:text-left">
        a new era of <span className="text-brand-darkGold">poker</span>
      </div>
      <div className="text-sm font-bold text-white sm:text-2xl">
        Join the play to earn revolution
      </div>
      <div className="flex flex-col items-center mt-10 gap-5 sm:flex-row justify-center sm:justify-start w-full sm:mt-0">
        <div className="bg-gradient-to-t w-4/5 from-yellow-600 to-yellow-100 p-0.5 rounded-full sm:w-fit">
          <Link to="/lobby">
            <button className="h-10 w-full sm:w-72 sm:h-16 sm:text-sm bg-gradient-to-l from-brand-darkGold via-brand-lightGold to-brand-darkGold uppercase font-bold border-orange shadow-orange rounded-full hover:opacity-80">
              play now
            </button>
          </Link>
        </div>
        <div className="bg-gradient-to-t from-yellow-600 to-yellow-100 p-0.5 rounded-full w-4/5 sm:w-fit">
          <button className="h-10 w-full sm:w-72 sm:h-16 sm:text-sm bg-black uppercase text-white font-bold rounded-full hover:opacity-90">
            how to play
          </button>
        </div>
      </div>
    </div>
  );
};

export default Main;
