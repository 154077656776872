import fireTable from "../../img/brand/goldFlameTitle.svg";
import load from "../../img/brand/loading.gif";
import { Link } from "react-router-dom";

const PlayModal = ({ showPlay, setShowPlay, loading, sending }) => {
  return (
    <div
      onClick={() => setShowPlay(!showPlay)}
      className={`${
        showPlay ? "" : "hidden"
      } flex items-center justify-center bg-brand-modal overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full inset-0 h-modal h-full`}
    >
      <div className="relative h-auto w-5/6 flex justify-center">
        <div className="bg-brand-modalBg py-6 sm:py-16 px-9 sm:px-32 font-sans relative rounded shadow dark:bg-gray-800">
          {loading && !sending ? (
            <div className="flex flex-col items-center gap-6 w-[350px]">
              <img src={load} className="w-32" alt="loading.." />
              <div className="w-full border-b-4 border-gray-200 max-w-[450px]"></div>
              <div className="text-sm sm:text-lg text-gray-400 max-w-[450px]">
                Waiting for the NFT to be minted!
              </div>
            </div>
          ) : loading && sending ? (
            <div className="flex flex-col items-center gap-6 w-[350px]">
              <img src={load} className="w-32" alt="loading.." />
              <div className="w-full border-b-4 border-gray-200 max-w-[450px]"></div>
              <div className="text-sm sm:text-lg text-gray-400 max-w-[450px]">
                We are sending to you the ammount to mint the NFT!
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-start gap-6">
              <img src={fireTable} className="w-10.5 sm:w-14" alt="fireImg" />
              <div className="flex flex-col gap-3 items-start w-full">
                <div className="text-white text-center font-bold text-lg sm:text-3xl">
                  NFT Minted!
                </div>
                <div className="text-sm sm:text-lg text-gray-400 max-w-[450px] pb-10 border-b-4 border-gray-200">
                  You have minted your members pass, click below to hit the
                  tables!
                </div>
                <div className="w-full">
                  <Link to="/lobby">
                    <div className="bg-gradient-to-t from-yellow-600 to-yellow-100 rounded-full p-0.5 mt-6 w-full sm:w-60">
                      <button
                        onClick={() => setShowPlay(false)}
                        className="h-10 w-full sm:h-14 text-sm sm:text-xl bg-gradient-to-l from-brand-darkGold via-brand-lightGold to-brand-darkGold uppercase font-bold shadow-orange rounded-full hover:opacity-80"
                      >
                        play now
                      </button>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlayModal;
