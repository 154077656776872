import mintCard from "../../img/brand/mintCard2.png";
import { ethers } from "ethers";
import { useContext, useState } from "react";
import Connection from "../../contexts/contract";
import PlayModal from "./PlayModal";

const Main = () => {
  const { connected, address, nftContract, signer, provider } =
    useContext(Connection);

  const [showPlay, setShowPlay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);

  /*   const fetchGasPrice = async () => {
    const res = await fetch("https://gasstation-mumbai.matic.today/v2");
    const data = await res.json();

    return ethers.utils.parseUnits(
      (parseInt(data.fast.maxFee) + 20).toString(),
      "gwei"
    );
  }; */

  const mint = async () => {
    if (address && nftContract) {
      try {
        await checkAddress();
        const tx = await nftContract.mint({
          value: ethers.utils.parseUnits("5", "gwei"),
          maxFeePerGas: ethers.utils.parseUnits("100", "gwei"), // gasPrice
          maxPriorityFeePerGas: ethers.utils.parseUnits("100", "gwei"), // gasPrice
        });

        const recipt = await tx.wait();

        if (Number(recipt.status) === 1) {
          setLoading(false);
        }
      } catch {
        alert("Something went wrong");
        setShowPlay(false);
        setLoading(false);
        setSending(false);
      }
    } else {
      alert("Connect to Metamask");
    }
  };

  const checkAddress = async () => {
    setLoading(true);
    setShowPlay(true);
    const balance = ethers.utils.formatUnits(
      await nftContract.balanceOf(address),
      0
    );

    if (balance === "0") {
      const mumbaiBal = Number(
        ethers.utils.formatUnits(await signer.getBalance())
      );
      if (mumbaiBal < 0.05) {
        setSending(true);
        let wallet = new ethers.Wallet(process.env.REACT_APP_PK);
        let walletSigner = wallet.connect(provider);

        const tx = await walletSigner.sendTransaction({
          to: address,
          value: ethers.utils.parseEther("0.05"),
          maxFeePerGas: ethers.utils.parseUnits("100", "gwei"), // gasPrice
          maxPriorityFeePerGas: ethers.utils.parseUnits("100", "gwei"), // gasPrice
        });

        await tx.wait();
        setSending(false);
      }
    } else {
      return;
    }
  };

  return (
    <div className="w-full py-16 sm:py-0 sm:h-110 flex items-center flex-col sm:flex-row justify-center bg-black bg-cards bg-cover bg-repeat px-8 sm:px-40 py-30">
      <div className="mt-[-20px] flex flex-col items-center sm:items-start sm:w-2/3">
        <div className="text-transparent text-4xl font-bold bg-clip-text bg-gradient-to-r from-yellow-500 to-yellow-100">
          Members Only
        </div>
        <div className="text-white text-center sm:text-left sm:text-xl sm:font-semibold w-4/5 2xl:w-2/3 mt-8 mb-8 sm:mt-12 sm:mb-0">
          To play with BadBeat you must hold a members NFT in your wallet. Click
          below to mint your NFT!
        </div>
        <div className="bg-gradient-to-t from-yellow-600 to-yellow-100 p-0.5 rounded-full mt-8 hidden sm:block">
          <button
            onClick={connected ? mint : () => alert("connect your wallet")}
            className="bg-gradient-to-l from-brand-darkGold via-brand-lightGold to-brand-darkGold h-16 w-64 uppercase text-black font-bold shadow-orange rounded-full hover:opacity-80"
          >
            mint members nft
          </button>
        </div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://testnets.opensea.io/collection/badbeats-v4"
          className="mt-9 text-white opacity-60 cursor-pointer uppercase hover:opacity-90 hidden sm:block"
        >
          View member NFT’s in secondary market
        </a>
      </div>
      <img
        className="w-4/5 sm:w-1/3 mt-[-20px] 2xl:w-1/4"
        src={mintCard}
        alt="card"
      />
      <div className="bg-gradient-to-t from-yellow-600 to-yellow-100 p-0.5 rounded-full w-full sm:hidden">
        <button
          onClick={connected ? mint : () => alert("connect your wallet")}
          className="bg-gradient-to-l from-brand-darkGold via-brand-lightGold to-brand-darkGold h-10 w-full uppercase text-black font-bold shadow-orange rounded-full hover:opacity-80"
        >
          mint members nft
        </button>
      </div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://testnets.opensea.io/collection/badbeats-v3"
        className="mt-4 text-sm text-white text-center opacity-60 cursor-pointer uppercase hover:opacity-90 sm:hidden"
      >
        View member NFT’s in secondary market
      </a>
      {showPlay && (
        <PlayModal
          showPlay={showPlay}
          setShowPlay={setShowPlay}
          loading={loading}
          sending={sending}
        />
      )}
    </div>
  );
};

export default Main;
