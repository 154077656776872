import { ethers } from "ethers";

export const CONTRACT_ADDRESS = "0x7A846FC75B247d0522dDe45e6bBA3d81b9f49Acb";

export const connectWallet = async (
  setConnected,
  setAddress,
  setProvider,
  setSigner,
  setChainId
) => {
  try {
    const { ethereum } = window;

    if (!ethereum) {
      alert("Get MetaMask!");
      return;
    }

    let prov = new ethers.providers.Web3Provider(ethereum);
    setProvider(prov);
    let sign = prov.getSigner();
    setSigner(sign);

    const accounts = await ethereum.request({
      method: "eth_requestAccounts",
    });
    setAddress(accounts[0]);
    setConnected(true);

    const chainId = await ethereum.request({ method: "eth_chainId" });
    setChainId(chainId);
  } catch (error) {
    console.log(error);
  }
};
