import { useContext } from "react";
import Connection from "../../contexts/contract";
import metamask from "../../img/brand/metamask.png";
import { connectWallet } from "../../lib/constants";

const ConnectModal = () => {
  const {
    connected,
    setConnected,
    setProvider,
    setAddress,
    setSigner,
    setChainId,
  } = useContext(Connection);

  return (
    <div
      className={`${
        !connected ? "" : "hidden"
      } flex items-center justify-center bg-brand-modal overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full`}
    >
      <div className="relative h-full md:h-auto">
        <div className="bg-brand-modalBg py-14 px-32 font-sans flex flex-col gap-3 text-xl relative rounded shadow dark:bg-gray-800">
          <div className="font-bold text-white text-3xl">
            Connect Wallet to Play
          </div>
          <div className="font-bold text-white text-lg">
            Connect your metamask or{" "}
            <span className="text-[#2BA5FA]">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://metamask.io/download/"
              >
                download here
              </a>
            </span>
            . <br /> More wallet providers will be added soon.
          </div>
          <div className="flex items-end">
            <div className="text-gray-400 text-base pb-2 border-b-4 border-b-brand-darkGold">
              Polygon
            </div>
            <div className="pb-2 border-b-4 border-b-gray-300 w-full"></div>
          </div>
          <div className="rounded-xl p-0.5 mt-6 bg-brand-lightGold w-full">
            <div
              onClick={() =>
                connectWallet(
                  setConnected,
                  setAddress,
                  setProvider,
                  setSigner,
                  setChainId
                )
              }
              className="w-full h-14 text-black bg-white gap-4 flex items-center font-bold rounded-xl hover:opacity-80"
            >
              <img src={metamask} alt="metmask logo" className="w-20" />
              Metamask
            </div>
          </div>
          <div className="w-full h-14 text-white bg-gray-400 flex items-center justify-center font-bold rounded-xl mt-3 hover:opacity-80">
            More Wallets Coming Soon
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectModal;
