import Connection from "../../contexts/contract";
import { useContext } from "react";
import { connectWallet } from "../../lib/constants";

const ConnectButtonMobile = () => {
  const {
    connected,
    address,
    setProvider,
    setSigner,
    setAddress,
    setConnected,
    setChainId,
  } = useContext(Connection);
  return (
    <div className="w-full h-10 p-0.5 bg-gradient-to-t from-yellow-600 to-yellow-100 rounded-full shadow-orange hover:opacity-85 sm:hidden">
      <button
        onClick={
          connected
            ? () => console.log("click")
            : () =>
                connectWallet(
                  setConnected,
                  setAddress,
                  setProvider,
                  setSigner,
                  setChainId
                )
        }
        className="bg-gradient-to-l from-brand-darkGold via-brand-lightGold to-brand-darkGold h-full w-full rounded-full uppercase text-black font-semibold "
      >
        {connected
          ? address.substring(0, 5) + ".." + address.substring(35)
          : "connect wallet"}
      </button>
    </div>
  );
};

export default ConnectButtonMobile;
