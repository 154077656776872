import poker from "../../img/brand/poker.svg";
import connect from "../../img/brand/connectPoker.svg";
import hand from "../../img/brand/hand.svg";
import money from "../../img/brand/money.svg";

const SecondBanner = () => {
  const cards = [
    {
      img: connect,
      title: "Connect",
      text: "With thousands of players across the world",
    },
    {
      img: hand,
      title: "Play",
      text: "Instantly on one of our many live poker tables",
    },
    {
      img: money,
      title: "Earn",
      text: "Exciting prizes and loyalty rewards every day ",
    },
  ];
  return (
    <div className="bg-suitsBg bg-cover bg-blend-darken h-110 flex flex-col items-center justify-center gap-6 text-white sm:flex-row sm:gap-14">
      {cards.map((card, i) => (
        <div
          key={i}
          className="w-2/3 bg-transparent rounded-2xl flex flex-col border-2 border-brand-gold gap-1.5 p-2 sm:gap-4 sm:p-4 sm:w-1/4 2xl:w-1/5"
        >
          <img src={poker} className="self-end w-5 sm:w-7" alt="" />
          <img
            src={card.img}
            className="w-8 self-center mt-[-20px] sm:w-1/5"
            alt=""
          />
          <div className="text-center text-lg font-bold sm:text-3xl">
            {card.title}
          </div>
          <div className="self-center text-center text-sm px-10 pb-3 max-w-[300px] sm:text-lg">
            {card.text}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SecondBanner;
