import { Link } from "react-router-dom";

const ContactBanner = () => {
  const sections = [
    { title: "contact", subtitles: ["hello@badbeat.com"] },
    {
      title: "contact",
      subtitles: ["about us", "our team", "roadmap", "events", "FAQ's"],
    },
    {
      title: "contact",
      subtitles: ["term & conditions", "privacy policies", "audit"],
    },
  ];

  return (
    <div className="h-80 bg-[#0F0F0F] text-white text-sm p-6 sm:p-14 font-bold">
      <div className="flex items-start flex-col sm:justify-around h-full sm:flex-row">
        {sections.map((s, i) => (
          <div key={i} className={`${i !== 0 ? "h-full" : ""} hidden sm:block`}>
            <div className="text-brand-darkGold capitalize">{s.title}</div>
            <div
              className={`text-roboto mt-7 capitalize ${
                i === 1 ? "h-2/3" : "h-2/5"
              } flex flex-col justify-between`}
            >
              {s.subtitles.map((sub, i) => (
                <div
                  className="cursor-pointer hover:text-brand-lightGold"
                  key={i}
                >
                  {sub}
                </div>
              ))}
            </div>
          </div>
        ))}

        <div className="w-full sm:w-fit">
          <div className="text-brand-darkGold text-2xl text-center sm:text-left sm:text-sm mx-auto sm:mx-0">
            Contact
          </div>
          <div className="text-roboto mt-3 sm:mt-7 capitalize flex flex-col w-full sm:w-fit">
            <input
              className="px-3 py-2 text-black outline-none"
              type="email"
              placeholder="jakered@gmail.com"
            />
            {/* add scroll up on click */}
            <Link to="/lobby">
              <div className="p-0.5 mt-6 sm:mt-3 bg-gradient-to-t from-yellow-600 to-yellow-100 rounded-full w-full sm:w-24">
                <button className="uppercase font-semibold self-start bg-gradient-to-l sm:bg-none from-brand-darkGold via-brand-lightGold to-brand-darkGold text-black sm:bg-black sm:text-white text-sm sm:text-xs text-center rounded-full h-7 w-full hover:opacity-80">
                  play <span className="sm:hidden">now</span>
                </button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactBanner;
