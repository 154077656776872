import Main from "../Mint/Main";
import PlayBanner from "../Mint/PlayBanner";

const Membership = () => {
  return (
    <div className="w-full flex flex-col justify-between">
      <Main />
      <PlayBanner />
    </div>
  );
};

export default Membership;
