import fireTable from "../../img/brand/goldFlameTitle.svg";

const IdModal = ({ chooseId, setChooseId, ids, setIdToPlay }) => {
  const click = (id) => {
    setChooseId(!chooseId);
    setIdToPlay(id);
  };
  return (
    <div
      onClick={() => setChooseId(!chooseId)}
      className={`${
        chooseId ? "" : "hidden"
      } flex items-center justify-center bg-brand-modal overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full`}
    >
      <div className="relative max-w-2xl h-full md:h-auto">
        <div className="bg-brand-modalBg py-14 px-32 font-sans flex flex-col gap-6 text-xl relative rounded shadow dark:bg-gray-800">
          <img src={fireTable} className="w-14" alt="fireImg" />
          <div className="flex flex-col gap-3">
            <div className="text-3xl text-white font-bold">
              You got a lot of NFTs!
            </div>
            <div className="text-gray-400 text-lg w-[450px] pb-10 border-b-4 border-gray-200">
              To play poker you need to choose one between all of your NFTs
            </div>
            <div className="grid grid-cols-5 gap-4 mt-2 mb-6">
              {ids.map((id) => (
                <div
                  key={id}
                  className="bg-gradient-to-t from-yellow-600 to-yellow-100 rounded-full w-fit p-0.5"
                >
                  <button
                    onClick={() => click(id)}
                    className="h-16 w-16 text-black bg-gradient-to-l from-brand-darkGold via-brand-lightGold to-brand-darkGold rounded-full uppercase font-bold border-orange shadow-orange hover:opacity-80"
                  >
                    {id}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdModal;
