import { Routes, Route } from "react-router-dom";
import Home from "./components/pages";
import Membership from "./components/pages/membership";
import Layout from "./components/_shared/Layout";
import Tables from "./components/pages/tables";
import { ConnectionProvider } from "./contexts/contract";

function App() {
  return (
    <ConnectionProvider>
      <Layout>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/membership" element={<Membership />} />
          <Route path="/lobby" element={<Tables />} />
        </Routes>
      </Layout>
    </ConnectionProvider>
  );
}

export default App;
