import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebookF,
  faYoutube,
  faInstagram,
  faTelegramPlane,
  faDiscord,
  faTwitch,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <div className="h-16 bg-black sm:bg-gradient-to-l from-brand-darkGold via-brand-lightGold to-brand-darkGold flex items-center justify-between text-black px-4 sm:px-40">
      <div className="capitalize text-sm font-roboto font-bold hidden sm:block">
        c 2022 Badbeat
      </div>
      <div className="w-1/4 hidden sm:block">
        <div className="flex items-center justify-between w-full">
          <div className="w-8  h-8 rounded-full bg-white flex items-center justify-center">
            <FontAwesomeIcon icon={faTwitter} />
          </div>
          <div className="w-8  h-8 rounded-full bg-white flex items-center justify-center">
            <FontAwesomeIcon icon={faFacebookF} />
          </div>
          <div className="w-8  h-8 rounded-full bg-white flex items-center justify-center">
            <FontAwesomeIcon icon={faYoutube} />
          </div>
          <div className="w-8  h-8 rounded-full bg-white flex items-center justify-center">
            <FontAwesomeIcon icon={faInstagram} />
          </div>
          <div className="w-8  h-8 rounded-full bg-white flex items-center justify-center">
            <FontAwesomeIcon icon={faTelegramPlane} />
          </div>
          <div className="w-8  h-8 rounded-full bg-white flex items-center justify-center">
            <FontAwesomeIcon icon={faDiscord} />
          </div>
          <div className="w-8  h-8 rounded-full bg-white flex items-center justify-center">
            <FontAwesomeIcon icon={faTwitch} />
          </div>
        </div>
      </div>
      <div className="font-bold text-blue-500 sm:hidden">
        Terms & Conditions
      </div>
      <div className="font-bold text-blue-500 sm:hidden">Place Holder</div>
    </div>
  );
};

export default Footer;
