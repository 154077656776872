import { Link } from "react-router-dom";

const PlayBanner = () => {
  return (
    <div className="bg-gradient-to-t from-yellow-600 to-yellow-100 p-0.5">
      <div className="w-full h-52 flex flex-col items-center justify-around bg-gradient-to-r from-brand-darkGold via-brand-lightGold to-brand-darkGold sm:flex-row">
        <div>
          <div className="max-w-fit w-4/5 mx-auto text-black text-2xl text-center font-ethno uppercase sm:max-w-[500px] sm:w-full sm:text-4xl sm:text-left">
            Join the play to earn revolution!
          </div>
        </div>
        <div className="bg-gradient-to-t w-4/5 from-yellow-600 to-yellow-100 p-0.5 rounded-full sm:w-1/6">
          <Link to="/lobby">
            <button className="h-10 w-full bg-black text-white text-sm uppercase font-bold hover:opacity-80 rounded-full sm:h-16 sm:text-base">
              play now
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PlayBanner;
